<template>
	<ContentBlock
		:size="3"
		:title="$t('db.docDetails.documentSettings')"
		:hasBorderUnderTitle="true"
	>
		<div class="has-padding-top-1">
			<ConfigOptions 
				:options="configOptions"
				:disabled="!canBeEdited"
				v-model:valueModel="configModel"
				@update:valueModel="configChanged"
			/>
		</div>
		<div class="has-padding-top-1">
			<VTitle classes="has-margin-bottom-0" :size="4" :text="$t('db.docDetails.description')" />

			<VTextarea
				v-model="document.description"
				:placeholder="$t('db.docDetails.description')"
				class="has-margin-bottom-4"
				:isNarrow="true"
				@change="markDocAsUpdated"
				:disabled="!canBeEdited"
			/>
		</div>
	</ContentBlock>

	<ContentBlock
		:size="3"
		:title="$t('general.fields')"
		:hasFullwidthBody="true"
	>
		<div class="is-flex has-bottom-divider has-padding-left-5 has-padding-right-5 has-margin-bottom-075">
			<VLink
				:text="$t('db.docDetails.addField')"
				icon="plus"
				:hasUnderline="true"
				@click.prevent.stop="addNewClicked"
				class="has-margin-bottom-05 has-margin-right-3"
				:isDisabled="!showAddFieldLink"
			/>

			<VSearch v-model="search" />
		</div>
		<SortableDataTable
			:scrollable="false"
			:stickyHeader="false"
			:rowClickedFn="inspectClicked"
			default-sort="order"
			default-sort-direction="ASC"
			:data="filteredData"
			tableClasses="scrollable-content"
			:columns="columns"
			:emptyText="$t('db.docDetails.table.noResults')"
			:draggable="canBeDragged"
			@dragstart="tableDragStart"
			@dragover="tableDragOver"
			@dragleave="tableDragLeave"
			@drop="tableDrop"
		/>
	</ContentBlock>

	<ContentBlock
		:size="3"
		:title="$t('db.docDetails.references')"
		:hasFullwidthBody="true"
	>
		<div class="is-flex has-bottom-divider has-padding-left-5 has-padding-right-5 has-margin-bottom-075">
			<VLink
				:text="$t('db.docDetails.addReference')"
				icon="plus"
				:hasUnderline="true"
				@click.prevent.stop="addNewRefClicked"
				class="has-margin-bottom-05 has-margin-right-3"
				:isDisabled="!canBeEdited"
			/>
		</div>
		<SortableDataTable
			defaultSort="document"
			:data="refTableRows"
			:columns="refColumns"
			:emptyText="$t('db.docDetails.referenceTable.noResults')"
		>
			<template
				v-for="(row, key) in refTableRows"
				:key="key"
				#[`child-${key}`]
			>
				<VField
					:label="$t('db.docDetails.referenceTable.chooseField')"
					:isFloating="true"
					:class="{
						'is-active': document.references[key]['child'],
					}"
				>
					<VSelect
						:options="fieldOptions()"
						v-model="document.references[key]['child']"
						@update:modelValue="referenceUpdated"
						:disabled="!canBeEdited"
					/>
				</VField>
			</template>
		</SortableDataTable>
	</ContentBlock>
</template>

<script>
import { mapGetters, useStore } from 'vuex';
import { reactive, toRefs } from '@vue/reactivity';
import { useI18n } from 'vue-i18n';
import Helpers from '@assets/scripts/helpers';
import Field from '@assets/scripts/components/field';
import {
	getStoreGetter,
	getStoreMutation,
} from '@assets/scripts/store/config';
import {
	formatFieldsForActionDocumentTable,
} from '@modules/DocumentBuilder/components/connection-document';

export default {
	name: 'ActionDocumentDetails',
	data: function () {
		return {
			search: '',
			// definition of table columns
			columns: [
				{
					label: '',
					field: 'order',
					sortable: true,
					searchable: false,
					visible: false,
					default: '',
					customSort: this.sortBySortOrder,
				},
				{
					label: this.$t('field.name'),
					field: 'field_name',
					searchable: true,
					default: this.$t('general.dash'),
				},
				{
					label: this.$t('field.type'),
					field: 'field_type',
					width: '15%',
					searchable: true,
					default: this.$t('general.dash'),
				},
				{
					label: this.$t('field.default_value'),
					field: 'default_value',
					width: '20%',
					searchable: true,
					default: this.$t('general.dash'),
				},
				{
					label: this.$t('field.validation'),
					field: 'validation',
					width: '20%',
					searchable: true,
					default: this.$t('general.dash'),
				},
				{
					label: this.$t('field.required_field'),
					field: 'required_field',
					width: '15%',
					default: this.$t('general.dash'),
				},
				{
					label: '',
					field: 'edit',
					sortable: false,
					default: '',
					component: 'VButton',
					cellClass: 'is-button-tool',
					args: {
						edit: {
							title: this.$t('general.edit'),
							isTool: true,
							icon: 'edit',
						},
						view: {
							title: this.$t('general.inspect'),
							isTool: true,
							icon: 'eye',
						}
					},
					click: this.inspectClicked,
				},
				{
					label: '',
					field: 'delete',
					sortable: false,
					default: '',
					component: 'VButton',
					cellClass: 'is-button-tool',
					args: {
						href: '',
						title: this.$t('general.delete'),
						isTool: true,
						class: '',
						icon: 'delete',
					},
					click: this.deleteClicked,
				},
			],
			// definition of reference columns
			refColumns: [
				{
					label: this.$t('db.docDetails.referenceTable.columns.document'),
					field: 'document',
					sortable: true,
					default: this.$t('general.dash'),
				},
				{
					label: this.$t('db.docDetails.referenceTable.columns.keyField'),
					field: 'key_field',
					sortable: true,
					default: this.$t('general.dash'),
				},
				{
					label: this.$t('db.docDetails.referenceTable.columns.foreignKey'),
					field: 'child',
					sortable: false,
					default: this.$t('general.dash'),
					component: 'VSelect',
				},
				{
					label: '',
					field: 'delete',
					sortable: false,
					default: '',
					component: 'VButton',
					cellClass: 'is-button-tool',
					args: {
						href: '',
						title: this.$t('general.delete'),
						isTool: true,
						class: '',
						icon: 'delete',
					},
					click: this.deleteRefClicked,
				},
			],
		};
	},
	setup: function () {
		const store = useStore();
		const { t } = useI18n();

		const state = reactive({
			/**
			 * Gets currently active Document
			 */
			document: store.getters[getStoreGetter('CURRENT_DOCUMENT', 'DB')],
			configOptions: [
				{
					value: 'create_always',
					label: t('db.docOptions.create_always'),
					info: t('db.docOptions.create_always_info'),
				},
				{
					value: 'create_if_not_exists',
					label: t('db.docOptions.create_if_not_exists'),
					info: t('db.docOptions.create_if_not_exists_info'),
				},
				{
					value: 'upsert',
					label: t('db.docOptions.upsert'),
					info: t('db.docOptions.upsert_info'),
				},
				{
					value: 'read',
					label: t('db.docOptions.read'),
					info: t('db.docOptions.read_info'),
				},
				{
					value: 'create_or_stop',
					label: t('db.docOptions.create_or_stop'),
					info: t('db.docOptions.create_or_stop_info'),
				},
				{
					value: 'only_composed',
					label: t('db.docOptions.only_composed'),
					info: t('db.docOptions.only_composed_info'),
				},
			],
			configModel: {},
		});

		state.configOptions.forEach((option) => {
			if (typeof state.document[option.value] !== 'undefined') {
				state.configModel[option.value] = state.document[option.value];
			}
		});

		return {
			...toRefs(state),
		};
	},
	computed: {
		...mapGetters({
			/**
			 * Boolean to indicate whether current document
			 * can be edited
			 */
			canBeEdited: getStoreGetter('CAN_BE_EDITED', 'DB'),
			/**
			 * Boolean to indicate whether current document
			 * can be extended
			 */
			canBeExtended: getStoreGetter('CAN_BE_EXTENDED', 'DB'),
		}),
		showAddFieldLink: function () {
			return this.canBeEdited || this.canBeExtended;
		},
		tableFields: function () {
			return formatFieldsForActionDocumentTable(this.document.fields, this.document);
		},
		searchableCols: function () {
			return Helpers.getSearchableColumns(this.columns);
		},
		filteredData: function () {
			// filter on search string
			const matches = Helpers.filterByString(
				this.tableFields,
				this.search,
				this.searchableCols
			);

			return matches;
		},
		refTableRows: function () {
			const rows = [];

			if (!this.document.references) return rows;

			// loop over document references
			this.document.references.forEach((row, index) => {
				rows.push({
					document: row.name,
					key_field: row.key,
					key: index,
					delete: this.canBeEdited,
				});
			});

			return rows;
		},
		canBeDragged: function () {
			return this.search === '';
		},
		orderForNewField: function () {
			return this.document.fields.reduce((prev, curr) => {
				return Math.min(prev, curr.order - 1);
			}, -1);
		}
	},
	methods: {
		markDocAsUpdated: function () {
			// mark document as modified whenever a
			// change is made
			this.$store.commit(
				getStoreMutation('MARK_MODIFIED', 'DB')
			);
		},
		configChanged: function () {
			Object.assign(this.document, this.configModel);
			this.markDocAsUpdated();
		},
		inspectClicked(row) {
			// open drawer with field information
			this.$store.commit(getStoreMutation('OPEN_DRAWER'), {
				type: 'documentFieldDetails',
				data: {
					key: row.key + 1, // add 1 to avoid issues with 0-based index
				}
			});
		},
		addNewClicked() {
			// open drawer with field information of new field
			this.$store.commit(getStoreMutation('OPEN_DRAWER'), {
				type: 'documentFieldDetails',
				data: {
					order: this.orderForNewField
				}
			});
		},
		deleteClicked({ key }) {
			// ask confirmation to delete field
			this.$store.commit(getStoreMutation('OPEN_CONFIRMATION'), {
				title: this.$t('db.docDetails.delete.confirm.title'),
				body: this.$t('db.docDetails.delete.confirm.body'),
				confirmButtonText: this.$t(
					'db.docDetails.delete.confirm.confirmButtonText'
				),
				confirmFn: () => {
					// do delete after confirmation
					this.document.fields.splice(key, 1);
					this.markDocAsUpdated();
				},
			});
		},
		fieldOptions: function () {
			// N.B.: not limited to specific field type for now,
			// since getting the field type of the key field
			// of the referenced document is complicated
			// since computed values ('refTableRows' in this case)
			// can not be async
			const result = [];

			this.document.fields.forEach((field) => {
				const name = field.name;

				result.push({
					value: name,
					text: Field.getNameAsPath(name),
				});
			});

			return result;
		},
		referenceUpdated: function () {
			this.markDocAsUpdated();
		},
		addNewRefClicked() {
			// open drawer with information of referencable documents
			this.$store.commit(getStoreMutation('OPEN_DRAWER'), {
				type: 'actionDocumentReference',
				data: {
					callback: (ref) => {
						// add selected reference to document
						this.document.references.push(ref);
						this.markDocAsUpdated();
					},
				},
			});
		},
		deleteRefClicked({ key }) {
			// ask confirmation to delete reference
			this.$store.commit(getStoreMutation('OPEN_CONFIRMATION'), {
				title: this.$t('db.docDetails.deleteRef.confirm.title'),
				body: this.$t('db.docDetails.deleteRef.confirm.body'),
				confirmButtonText: this.$t(
					'db.docDetails.deleteRef.confirm.confirmButtonText'
				),
				confirmFn: () => {
					// do delete after confirmation
					this.document.references.splice(key, 1);
					this.markDocAsUpdated();
				},
			});
		},
		sortBySortOrder(a, b) {
			return Field.orderParentChildArrayBySortOrder(a, b, this.document.fields);
		},
		// called when dragging in table starts
		tableDragStart(payload) {
			this.draggingRow = payload.row;
		},
		// called when row in table is dragged over another row
		tableDragOver(payload) {
			payload.event.target.closest('tr').classList.add('is-selected');
			payload.event.preventDefault();
		},
		tableDragLeave(payload) {
			payload.event.target.closest('tr').classList.remove('is-selected');
			payload.event.preventDefault();
		},
		// called when dragged row in table is dropped in new position
		tableDrop(payload) {
			payload.event.target.closest('tr').classList.remove('is-selected');
			const { row: droppedOnRow } = payload;

			// do nothing if dropped in original location
			if (this.draggingRow === droppedOnRow) return;

			// check if dragged field and field on dropped location
			// are siblings. Show error if not.
			if (!Field.fieldsAreSiblings(this.draggingRow.name, droppedOnRow.name)) {
				// throw error
				log(this.$t('db.error.canNotDropHere'), 'danger');
				return;
			}

			// update sort order in document
			this.updateSortOrder(
				this.draggingRow.order,
				droppedOnRow.order
			);
		},
		updateSortOrder(oldPos, newPos) {
			// do nothing if new position is same as
			// old position
			if (oldPos === newPos) return;

			// check if row was moved either up or down
			const movedUp = oldPos > newPos;

			// loop over all sorting rules
			this.document.fields.forEach((row) => {
				const { order } = row;

				// set moved row to new position
				if (order === oldPos) {
					row.order = newPos;
				} else if (
					// check if moved row was moved up, and
					// current row was sorted above the old position
					// and below or equal to the new position
					movedUp &&
					row.order < oldPos &&
					row.order >= newPos
				) {
					// increase position by 1
					row.order++;
				} else if (
					// check if moved row was moved down, and
					// current row was sorted below the old position
					// and above or equal to the new position
					!movedUp &&
					row.order > oldPos &&
					row.order <= newPos
				) {
					// decrease position by 1
					row.order--;
				}
			});

			this.markDocAsUpdated();
		},
	},
};
</script>
