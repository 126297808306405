<template>
	<ContentBlock
		:size="3"
		:title="$t('db.docDetails.documentSettings')"
		:hasBorderUnderTitle="true"
	>
		<div class="has-padding-top-1">
			<VTitle classes="has-margin-bottom-0" :size="4" :text="$t('db.docDetails.description')" />

			<VTextarea
				v-model="document.description"
				:placeholder="$t('db.docDetails.description')"
				class="has-margin-bottom-4"
				:isNarrow="true"
				@change="markDocAsUpdated"
				:disabled="!canBeEdited"
			/>
		</div>
	</ContentBlock>
	<ContentBlock
		:size="3"
		:title="$t('general.fields')"
		:hasFullwidthBody="true"
	>
		<div class="is-flex has-bottom-divider has-padding-left-5 has-padding-right-5 has-margin-bottom-075">
			<VLink
				:text="$t('db.docDetails.addField')"
				icon="plus"
				:hasUnderline="true"
				@click.prevent.stop="addNewClicked"
				class="has-margin-bottom-05 has-margin-right-3"
				:isDisabled="!showAddFieldLink"
			/>

			<VSearch v-model="search" />
		</div>

		<SortableDataTable
			:scrollable="false"
			:stickyHeader="false"
			:rowClickedFn="inspectClicked"
			defaultSort=""
			:data="filteredData"
			tableClasses="scrollable-content"
			:columns="columns"
			:emptyText="$t('db.docDetails.table.noResults')"
		/>
	</ContentBlock>
</template>

<script>
import { mapGetters, useStore } from 'vuex';
import { reactive, toRefs } from '@vue/reactivity';
import Helpers from '@assets/scripts/helpers';
import {
	getStoreGetter,
	getStoreMutation,
} from '@assets/scripts/store/config';
import {
	formatFieldsForListDocumentTable,
} from '@modules/DocumentBuilder/components/connection-document';

export default {
	name: 'ListDocumentDetails',
	data: function () {
		return {
			search: '',
			// definition of table columns
			columns: [
				{
					label: this.$t('field.name'),
					field: 'field_name',
					sortable: true,
					searchable: true,
					default: this.$t('general.dash'),
				},
				{
					label: this.$t('field.type'),
					field: 'field_type',
					width: '15%',
					sortable: true,
					searchable: true,
					default: this.$t('general.dash'),
				},
				{
					label: this.$t('field.default_value'),
					field: 'default_value',
					width: '20%',
					sortable: true,
					searchable: true,
					default: this.$t('general.dash'),
				},
				{
					label: this.$t('field.validation'),
					field: 'validation',
					width: '20%',
					sortable: true,
					searchable: true,
					default: this.$t('general.dash'),
				},
				{
					label: this.$t('field.required_field'),
					field: 'required_field',
					width: '15%',
					sortable: true,
					default: this.$t('general.dash'),
				},
				{
					label: this.$t('field.is_returnable'),
					field: 'is_returnable',
					sortable: true,
					searchable: true,
				},
				{
					label: '',
					field: 'edit',
					sortable: false,
					default: '',
					component: 'VButton',
					cellClass: 'is-button-tool',
					args: {
						edit: {
							title: this.$t('general.edit'),
							isTool: true,
							icon: 'edit',
						},
						view: {
							title: this.$t('general.inspect'),
							isTool: true,
							icon: 'eye',
						}
					},
					click: this.inspectClicked,
				},
				{
					label: '',
					field: 'delete',
					sortable: false,
					default: '',
					component: 'VButton',
					cellClass: 'is-button-tool',
					args: {
						href: '',
						title: this.$t('general.delete'),
						isTool: true,
						class: '',
						icon: 'delete',
					},
					click: this.deleteClicked,
				},
			],
		};
	},
	setup: function () {
		const store = useStore();

		const state = reactive({
			/**
			 * Gets currently active Document
			 */
			document: store.getters[getStoreGetter('CURRENT_DOCUMENT', 'DB')],
		});

		return {
			...toRefs(state),
		};
	},
	computed: {
		...mapGetters({
			/**
			 * Boolean to indicate whether current document
			 * can be edited
			 */
			canBeEdited: getStoreGetter('CAN_BE_EDITED', 'DB'),
			/**
			 * Boolean to indicate whether current document
			 * can be extended
			 */
			canBeExtended: getStoreGetter('CAN_BE_EXTENDED', 'DB'),
		}),
		showAddFieldLink: function () {
			return this.canBeEdited || this.canBeExtended;
		},
		tableFields: function () {
			return formatFieldsForListDocumentTable(this.document.fields, this.document);
		},
		searchableCols: function () {
			return Helpers.getSearchableColumns(this.columns);
		},
		filteredData: function () {
			// filter on search string
			const matches = Helpers.filterByString(
				this.tableFields,
				this.search,
				this.searchableCols
			);

			return matches;
		},
	},
	methods: {
		markDocAsUpdated: function () {
			// mark document as modified whenever a
			// change is made
			this.$store.commit(
				getStoreMutation('MARK_MODIFIED', 'DB')
			);
		},
		inspectClicked({ key }) {
			// open drawer with field information
			this.$store.commit(getStoreMutation('OPEN_DRAWER'), {
				type: 'documentFieldDetails',
				data: {
					key: key + 1, // add 1 to avoid issues with 0-based index
				},
			});
		},
		addNewClicked() {
			// open drawer with field information of new field
			this.$store.commit(getStoreMutation('OPEN_DRAWER'), {
				type: 'documentFieldDetails',
			});
		},
		deleteClicked({ key }) {
			// ask confirmation to delete field
			this.$store.commit(getStoreMutation('OPEN_CONFIRMATION'), {
				title: this.$t('db.docDetails.delete.confirm.title'),
				body: this.$t('db.docDetails.delete.confirm.body'),
				confirmButtonText: this.$t(
					'db.docDetails.delete.confirm.confirmButtonText'
				),
				confirmFn: () => {
					// do delete after confirmation
					this.document.fields.splice(key, 1);
					this.markDocAsUpdated();
				},
			});
		},
	},
};
</script>
