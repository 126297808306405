<template>
	<div class="page-with-options-wrapper">
		<TheAppOptions
			:backButtonText="$t('db.docDetails.backToOverview')"
			:backClickedFn="closeDocument"
			:nameLabel="$t('db.docDetails.docName')"
			v-model:name="docName"
			@update:name="updateName"
			:saveClickedFn="saveDocument"
			:editMode="canEditDocName" 
			:showButtons="showButtons"
			:canBeSaved="canBeSaved"
			:canBePublished="canBePublished"
			:publishClickedFn="publishDocument"
			:canBeValidated="canBeValidated"
			:validateClickedFn="validateDocument"
			:validationErrorListItemIsClickable="false"
			:trimName="true"
		/>

		<div class="page-content js-page-content">
			<Component
				v-if="documentComponent"
				:is="documentComponent"
			/>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
	getStoreGetter,
	getStoreMutation,
	getStoreAction,
} from '@assets/scripts/store/config';
import {
	getDocType,
} from '@modules/DocumentBuilder/components/connection-document';
import TheAppOptions from '@materials/structures/TheAppOptions.vue';

import ActionDocumentDetails from '@modules/DocumentBuilder/materials/documents/ActionDocumentDetails.vue';
import ListDocumentDetails from '@modules/DocumentBuilder/materials/documents/ListDocumentDetails.vue';

export default {
	name: 'TheDocumentDetails',
	components: {
		ActionDocumentDetails,
		ListDocumentDetails,
		TheAppOptions,
	},
	data: function () {
		return {
			docName: '',
		};
	},
	mounted: function () {
		this.docName =
			this.document && this.document.name
				? this.document.name
				: '';

		// clear validation when document is loded
		this.$store.dispatch(getStoreAction('CLEAR_VALIDATION'));
	},
	computed: {
		...mapGetters({
			/**
			 * Boolean to indicate whether user modified the document
			 */
			modified: getStoreGetter('MODIFIED', 'DB'),
			/**
			 * Gets currently active Document
			 */
			document: getStoreGetter('CURRENT_DOCUMENT', 'DB'),
			/**
			 * Boolean to indicate whether current document
			 * can be edited
			 */
			canBeEdited: getStoreGetter('CAN_BE_EDITED', 'DB'),
			/**
			 * Boolean to indicate whether current document name
			 * can be edited
			 */
			canEditDocName: getStoreGetter('CAN_EDIT_DOC_NAME', 'DB'),
			/**
			 * Boolean to indicate whether current document
			 * can be extended
			 */
			canBeExtended: getStoreGetter('CAN_BE_EXTENDED', 'DB'),
			/**
			 * Boolean to indicate whether current document
			 * can be saved
			 */
			canBeSaved: getStoreGetter('CAN_BE_SAVED', 'DB'),
			/**
			 * Boolean to indicate whether current document
			 * can be published
			 */
			canBePublished: getStoreGetter('CAN_BE_PUBLISHED', 'DB'),
		}),
		showButtons: function () {
			return this.canBeEdited || this.canBeExtended;
		},
		canBeValidated: function () {
			return this.canBeEdited || this.canBePublished || this.canBeExtended;
		},
		docType: function () {
			return this.document ? getDocType(this.document.type) : false;
		},
		documentComponent: function () {
			let result = false;

			switch (this.docType.toLowerCase()) {
				case 'action':
					result = 'ActionDocumentDetails';
					break;
				case 'list':
					result = 'ListDocumentDetails';
					break;
			}

			return result;
		},
	},
	methods: {
		closeDocument: function () {
			const closeOverlay = () => {
				// dispatch action to unset document
				this.$store.dispatch(getStoreAction('UNSET_DOCUMENT', 'DB'));
			};


			if (!this.modified) {
				// close immediately if config has not been modified
				closeOverlay();
			} else {
				// ask confirmation before closing if a change has been made
				// to the Document
				this.$store.commit(getStoreMutation('OPEN_CONFIRMATION'), {
					title: this.$t('db.docDetails.close.confirm.title'),
					body: this.$t('db.docDetails.close.confirm.body'),
					confirmButtonText: this.$t(
						'db.docDetails.close.confirm.confirmButtonText'
					),
					confirmFn: () => {
						// close after confirmation
						closeOverlay();
					},
				});
			}
		},
		validateDocument: function () {
			// dispatch action to Validate document
			this.$store.dispatch(
				getStoreAction('VALIDATE_CURRENT_DOCUMENT', 'DB')
			);
		},
		saveDocument: function () {
			this.$store.dispatch(
				getStoreAction('SAVE_CURRENT_DOCUMENT', 'DB')
			);
		},
		publishDocument: function () {
			// ask confirmation before publishing
			this.$store.commit(getStoreMutation('OPEN_CONFIRMATION'), {
				title: this.$t('db.docDetails.publish.confirm.title'),
				body: this.$t('db.docDetails.publish.confirm.body'),
				confirmButtonText: this.$t(
					'db.docDetails.publish.confirm.confirmButtonText'
				),
				confirmFn: () => {
					// dispatch action to publish document
					this.$store.dispatch(
						getStoreAction('PUBLISH_CURRENT_DOCUMENT', 'DB')
					);
				},
			});
		},
		updateName: function (newName) {
			// commit mutation in store
			this.$store.commit(getStoreMutation('SET_NAME', 'DB'), newName);
		},
	},
};
</script>
