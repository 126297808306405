<template>
	<VOverlay
		:hasBackground="false"
		:hasJsonButton="false"
		:hasCloseButton="false"
		:closeOnBackgroundClick="false"
		:isLarge="true"
		:isVCentered="false"
		:hasFullwidthBody="true"
		:hasScrollableContent="true"
		v-model="overlayIsActive"
		:title="$t('db.documentList.title')"
		:useFocusTrap="false"
		:closeWithEscape="false"
	>
		<VTabs v-if="connections">
			<VTabItem v-for="(item, index) in connections" :key="index" :title="item.name" :onOpen="() => tabOpened(item)">
				<Suspense>
					<DocumentList :connGuid="item.conn_guid" />
				</Suspense>
			</VTabItem>
		</VTabs>
	</VOverlay>
</template>

<script>
import { getStoreMutation } from '@assets/scripts/store/config';
import DocumentList from '@modules/DocumentBuilder/materials/structures/DocumentList.vue';
import { useApiAsync } from '@assets/scripts/composables/useApi';
import { GET_CONNECTIONS } from '@assets/scripts/api/config';

export default {
	name: 'TheDocumentOverview',
	components: {
		DocumentList,
	},
	data: function () {
		return {
			overlayIsActive: true,
		};
	},
	async setup () {
		const connections = await useApiAsync(GET_CONNECTIONS);

		return { connections };
	},
	methods: {
		tabOpened: function (connection) {
			this.$store.commit(
				getStoreMutation('SET_CURRENT_CONNECTION', 'DB'),
				connection
			);
		},
	},
};
</script>
