<template>
	<VOverlay
		:title="$t('db.newDocument.title')"
		:size="2"
		:isVCentered="false"
		:closeOnBackgroundClick="true"
		:hasBackground="true"
		:modelValue="true"
		@update:modelValue="overlayClosed"
		:modalHasSharpCorner="true"
		@keydown.meta.s.prevent.stop="submit"
	>
		<VTitle classes="has-margin-bottom-0" :size="4" :text="$t('db.newDocument.whatName')" />

		<VInputString
			:isRequired="true"
			v-model:valueModel="docNameValue"
			:label="$t('db.newDocument.nameInputLabel')"
			:trim="true"
			@update:valueModel="markModified"
		/>

		<VTitle classes="has-margin-bottom-0" :size="4" :text="$t('db.newDocument.giveDescription')" />

		<VTextarea
			v-model="docDescription"
			:placeholder="$t('db.newDocument.giveDescription')"
			class="has-margin-bottom-4"
			:isNarrow="true"
		/>

		<VTitle classes="has-margin-bottom-0" :size="4" :text="$t('db.newDocument.whatType')" />

		<VField :isGrouped="true">
			<VOption
				v-for="(item, key) in typeOptions"
				:isButton="true"
				:key="key"
				:value="item.id"
				:label="item.label"
				v-model="typeValue"
				type="radio"
			/>
		</VField>

		<VTitle classes="has-margin-bottom-0" :size="4" :text="$t('db.newDocument.whatConnection')" />

		<VField
			:label="$t('db.newDocument.selectConnection')"
			:isFloating="true"
			:class="{ 'is-active': connValue }"
		>
			<VSelect :options="connOptions" v-model="connValue" />
		</VField>

		<template #footer>
			<VButton
				:text="$t('db.newDocument.submitButton')"
				icon="chevron-right"
				:iconOnRight="true"
				classes="button-modal-footer"
				:isDisabled="!isValid"
				@clicked="submit"
			/>
		</template>
	</VOverlay>
</template>

<script>
import { mapGetters } from 'vuex';
import { getStoreAction, getStoreGetter, getStoreMutation } from '@assets/scripts/store/config';
import Helpers from '@assets/scripts/helpers';
import { docTypes, translateDocType } from '@modules/DocumentBuilder/components/connection-document';
import { useApiAsync } from '@assets/scripts/composables/useApi';
import { GET_CONNECTIONS } from '@assets/scripts/api/config';

export default {
	name: 'NewDocument',
	data: function () {
		const typeOptions = [];

		for (const key in docTypes) {
			typeOptions.push({
				id: key,
				label: translateDocType(docTypes[key]),
			});
		}

		return {
			typeOptions,
			typeValue: false,
			connOptions: [],
			connValue: false,
			docNameValue: '',
			docDescription: '',
		};
	},
	computed: {
		...mapGetters({
			currentConnection: getStoreGetter('CURRENT_CONNECTION', 'DB'),
			modified: getStoreGetter('MODIFIED', 'DB'),
		}),
		isValid: function () {
			return this.typeValue && this.connValue && this.docNameValue.trim();
		},
	},
	mounted: async function () {
		// get all available connection
		const connections = await useApiAsync(GET_CONNECTIONS);

		// format connections to use in VSelect component
		if (connections.length > 0) {
			this.connOptions = Helpers.mapObjectArrayForSelect(connections, {
				key: 'conn_guid',
				text: 'name',
			});
		}

		if (this.currentConnection) this.connValue = this.currentConnection;
	},
	methods: {
		markModified: function () {
			// no need to trigger this mutation more than once
			if (this.modified) return;

			// mark document as modified whenever a
			// change is made
			this.$store.commit(
				getStoreMutation('MARK_MODIFIED', 'DB')
			);
		},
		overlayClosed: function () {
			this.$store.dispatch(
				getStoreAction('TOGGLE_CREATE_NEW', 'DB'),
				false
			);
		},
		submit: function () {
			if (!this.isValid) return;

			this.$store.dispatch(getStoreAction('NEW_DOCUMENT', 'DB'), {
				name: this.docNameValue.trim(),
				description: this.docDescription.trim(),
				type: this.typeValue,
				conn_guid: this.connValue,
			});
		},
	},
};
</script>
