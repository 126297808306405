<template>
	<div class="content-header has-bottom-divider has-padding-top-3 has-margin">
		<div class="field is-grouped is-flex-grow-1 is-align-items-end">
			<VField
				:label="$t('db.docList.docTypes')"
				:isFloating="true"
				class="has-margin-right-1"
				:class="{ 'is-active': docTypeValue }"
			>
				<VSelect :options="docTypeOptions" v-model="docTypeValue" />
			</VField>

			<VLink
				v-if="canEditDoc"
				:text="$t('db.docList.addDocument')"
				icon="plus"
				:hasUnderline="true"
				@click.prevent.stop="createNewClicked"
				class="has-margin-right-3 has-margin-bottom-05"
			/>

			<VSearch v-model="search" />
		</div>
	</div>

	<div class="scrollable-content">
		<SortableDataTable
			:scrollable="false"
			:rowClickedFn="rowClicked"
			defaultSort="last_time_edited"
			sortDirection="DESC"
			:data="filteredData"
			:columns="columns"
			:emptyText="$t('db.docList.table.noResults')"
		/>
	</div>
</template>

<script>
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { reactive, toRefs } from '@vue/reactivity';
import { getStoreAction, getStoreMutation } from '@assets/scripts/store/config';
import { docTypes, translateDocType, formatForTable } from '@modules/DocumentBuilder/components/connection-document';
import Helpers from '@assets/scripts/helpers';
import { useApiAsync } from '@assets/scripts/composables/useApi';
import { useCreateNewOnKeydown } from '@assets/scripts/composables/useCreateNewOnKeydown';
import usePermission from '@assets/scripts/composables/usePermission';
import { GET_DOCUMENTS } from '@modules/DocumentBuilder/endpoints';

export default {
	name: 'DocumentList',
	props: {
		connGuid: {
			type: String,
			required: true,
		}
	},
	data: function () {
		return {
			// default value for Document Type filter
			docTypeValue: this.$t('general.all'),
			search: '',
		};
	},
	async setup (props) {
		const store = useStore();
		const { t } = useI18n();

		const state = reactive({
			documents: [],
			/**
			 * Boolean to indicate whether the current
			 * user can create documents
			 */
			canEditDoc: usePermission('Upsert', 'DocumentBuilder'),
		});

		const createNewClicked = () => {
			store.dispatch(
				getStoreAction('TOGGLE_CREATE_NEW', 'DB'),
				true
			);
		};
		if(state.canEditDoc) {
			useCreateNewOnKeydown(createNewClicked);
		}

		const loadDocuments = async () => {
			// get all Connection Documents
			state.documents = await useApiAsync(GET_DOCUMENTS, {
				keys: {
					connection: props.connGuid
				}
			});
		};

		// subscribe to store action
		const unsubscribeAction = store.subscribeAction({
			after: async ({ type, payload }) => {
				// check action type and relevant connection GUID
				if (
					type === getStoreAction('UPDATE_DOCUMENT_LIST', 'DB') &&
					payload === props.connGuid
				) {
					// reload document list if needed
					await loadDocuments(true);
				}
			}
		});

		await loadDocuments();

		// values for document type filter
		const docTypeOptions =  [
			{
				value: t('general.all'),
				text: t('general.all'),
			},
		];

		Object.values(docTypes).forEach((type) => {
			docTypeOptions.push({
				value: type,
				text: translateDocType(type),
			});
		});

		return { ...toRefs(state), docTypeOptions, unsubscribeAction, createNewClicked};
	},
	computed: {
		columns: function() {
			return [
				{
					label: this.$t('db.docList.table.columns.doc_name'),
					field: 'doc_name',
					sortable: true,
					searchable: true,
					cellClass: 'has-text-weight-semibold',
					// width: '30%',
				},
				{
					label: this.$t('db.docList.table.columns.type'),
					field: 'type_name',
					sortable: true,
					searchable: true,
					// width: '15%',
				},
				{
					label: this.$t('db.docList.table.columns.last_time_edited'),
					field: 'last_time_edited',
					sortable: true,
					searchable: true,
					isDate: true,
					// width: '25%',
				},
				{
					label: this.$t('db.docList.table.columns.status'),
					field: 'status',
					sortable: true,
					searchable: true,
					// width: '15%',
				},
				{
					label: '',
					field: 'edit',
					sortable: false,
					default: '',
					component: 'VButton',
					cellClass: 'is-button-tool',
					args: {
						edit: {
							title: this.$t('general.edit'),
							isTool: true,
							icon: 'edit',
						},
						view: {
							title: this.$t('general.inspect'),
							isTool: true,
							icon: 'eye',
						}
					},
					click: this.rowClicked,
				},
				{
					label: '',
					field: 'delete',
					sortable: false,
					default: '',
					component: 'VButton',
					cellClass: 'is-button-tool',
					args: {
						href: '',
						title: this.$t('general.delete'),
						isTool: true,
						class: '',
						icon: 'delete',
					},
					click: this.deleteClicked,
				},
			];
		},
		tableFields: function () {
			return formatForTable(this.documents);
		},
		searchableCols: function () {
			return Helpers.getSearchableColumns(this.columns);
		},
		filteredData: function () {
			// loop over all documents
			const newData = this.tableFields.filter((row) => {
				// filter on Doc type
				return  Helpers.filterByValue(
					row,
					'type',
					this.docTypeValue,
					this.$t('general.all')
				);
			});

			// return if no rows match
			if (this.search.length === 0 || newData.length === 0) return newData;

			// filter on search string
			return Helpers.filterByString(
				newData,
				this.search,
				this.searchableCols
			);
		},
	},
	methods: {
		rowClicked({ guid }) {
			// dispatch action to show clicked document
			this.$store.dispatch(getStoreAction('LOAD_AND_SHOW_DOCUMENT', 'DB'), {
				guid,
				conn_guid: this.connGuid,
			});
		},
		deleteClicked({ guid }) {
			// ask confirmation to delete document
			this.$store.commit(getStoreMutation('OPEN_CONFIRMATION'), {
				title: this.$t('db.docList.delete.confirm.title'),
				body: this.$t('db.docList.delete.confirm.body'),
				confirmButtonText: this.$t(
					'db.docList.delete.confirm.confirmButtonText'
				),
				confirmFn: () => {
					// dispatch action to delete clicked document
					this.$store.dispatch(
						getStoreAction('DELETE_DOCUMENT', 'DB'),
						{
							guid,
							conn_guid: this.connGuid,
						}
					);
				},
			});
		},
		sortByDate(a, b, isAsc) {
			return Helpers.date.sortByIsoDate(a.time, b.time, isAsc);
		},
	},
	unmounted: function () {
		// unsubscribe from actions
		this.unsubscribeAction();
	},
};
</script>
