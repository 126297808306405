<template>
	<div class="app-layer" :class="{ 'is-active': showDocDetails }">
		<TheDocumentDetails v-if="showDocDetails" />
	</div>
	<div
		class="overlay-layer app-layer"
		:class="{ 'is-active': !showDocDetails }"
	>
		<NewDocument v-if="showNewDocumentModal" />

		<Suspense>
			<TheDocumentOverview v-show="showOverview" />
		</Suspense>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getStoreAction, getStoreGetter } from '@assets/scripts/store/config';
import usePermission from '@assets/scripts/composables/usePermission';

import TheDocumentDetails from '@modules/DocumentBuilder/materials/structures/TheDocumentDetails.vue';
import TheDocumentOverview from '@modules/DocumentBuilder/materials/TheDocumentOverview.vue';

import NewDocument from '@modules/DocumentBuilder/materials/structures/NewDocument.vue';

export default {
	name: 'TheDocumentBuilder',
	components: {
		TheDocumentDetails,
		TheDocumentOverview,
		NewDocument,
	},
	computed: {
		...mapGetters({
			/**
			 * Boolean to indicate whether the form to
			 * create a new document should be displayed
			 */
			showCreateNew: getStoreGetter('SHOW_CREATE_NEW', 'DB'),
			/**
			 * Boolean to indicate whether a document is
			 * currently active
			 */
			documentActive: getStoreGetter('DOCUMENT_ACTIVE', 'DB'),
		}),
		/**
		 * Boolean to indicate whether the current
		 * user can create documents
		 */
		canEditDoc: function() {
			return usePermission('Upsert', 'DocumentBuilder');
		},
		/**
		 * Boolean to indicate whether the current
		 * user can read (view) documents
		 */
		canReadDoc: function () {
			return usePermission('Read', 'DocumentBuilder');
		},
		showNewDocumentModal: function () {
			return this.showCreateNew && this.canEditDoc;
		},
		showDocDetails: function () {
			return !this.showNewDocumentModal && this.documentActive && this.canReadDoc;
		},
		showOverview: function () {
			return !this.showNewDocumentModal && !this.showDocDetails && this.canReadDoc;
		}
	},
	unmounted: function () {
		// reset store to default state when page is unmounted
		this.$store.dispatch(getStoreAction('RESET', 'DB'));
	},
};
</script>
